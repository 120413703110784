import React, {useState, useEffect, useMemo} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {matchPath, useLocation} from 'react-router-dom'

import {useCart} from '../../hooks'

import {index, saveNotificationLinks} from '../../actions'

import Dialog from '@mui/material/Dialog'

import Sidebar from '../sidebar/Sidebar'
import SearchPageMobile from './mobile/SearchPageMobile'
import AuthenticatedHeader from './AuthenticatedHeader'
import UnauthenticatedHeader from './UnauthenticatedHeader'
import AuthenticatedHeaderMobile from './mobile/AuthenticatedHeaderMobile'
import UnauthenticatedHeaderMobile from './mobile/UnauthenticatedHeaderMobile'
import HomePageAuthenticatedHeader from './home-page/HomePageAuthenticatedHeader'
import HomePageUnauthenticatedHeader from './home-page/HomePageUnauthenticatedHeader'


import SlideTransition from '../generics/SlideTransition'



const noHeaderPaths = [
  '/messages',
  '/user/confirm-email/*',
  '/user/update-email/*',
  '/sign-up',
  '/sign-in',
  '/reset-password',
  '/legal/mobile',
  '/settings',
  '/merchants/:type/:id/registration',
  '/merchants/:type/:id/registration/return',
  '/merchants/:type/:id/registration/return/reauth'
]





const cssStyles = ({currentPath}) => ({
  header: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 60px;
    max-height: 60px;
    overflow: hidden;
    background-color: ${['/home', '/map'].includes(currentPath) ? 'transparent' : '#000'};
  `,
  paper: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    overflow-y: scroll;
  `
})





const Header = props => {

  const location = useLocation()

  const currentPath = location.pathname


  const isMobileSearchBar = useMediaQuery('(max-width: 1100px)')
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'))


  const styles = cssStyles({currentPath})

  const iconColor = ['/home', '/map'].includes(currentPath) ? 'primary' : 'secondary' 


  const [open, setOpen] = useState(false)


  const {index, isAuthenticated, saveNotificationLinks} = props



  const isShowing = useMemo(() => {
    if (currentPath === '/') return false

    for (const path of noHeaderPaths) {
      if (matchPath({path}, currentPath)) return false
    }

    return true
  }, [currentPath])



  // Load and save user cart information
  useCart()



  // Prevent layout shifts when a MUI modal is shown
  useEffect(() => {
    const header = document.getElementById('header')

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(function(mutationRecord) {
        header.style.paddingRight = document.body.style.paddingRight
      })
    })

    const target = document.body
    observer.observe(target, {attributes : true, attributeFilter : ['style']})


    return () => {
      observer.disconnect()
    } 
  }, [])

  


  useEffect(() => {
    if (isAuthenticated) {
      index('/user/notifications', 'NOTIFICATIONS').then(response => {
        if (response.links) {
          saveNotificationLinks(response.links)
        }
      })
    }
  }, [index, isAuthenticated, saveNotificationLinks])



  function showSearchModal() {
    setOpen(true)
  }


  function close() {
    setOpen(false)
  }


  const sidebar = <Sidebar menuIconColor={iconColor} />


  return (
    <header id='header' css={styles.header} style={{display: isShowing ? 'block' : 'none'}}>
      {(() => {
        if (isMobileSearchBar) {
          return (isAuthenticated 
            ? <AuthenticatedHeaderMobile Sidebar={sidebar} showSearchModal={showSearchModal} /> 
            : <UnauthenticatedHeaderMobile Sidebar={sidebar} showSearchModal={showSearchModal} />
          )
        } else if (['/home', '/map'].includes(currentPath) && isLargeScreen) {
            return (isAuthenticated 
              ? <HomePageAuthenticatedHeader Sidebar={sidebar} />
              : <HomePageUnauthenticatedHeader Sidebar={sidebar} />
            )
        } else {
          return (isAuthenticated 
            ? <AuthenticatedHeader Sidebar={sidebar}/> 
            : <UnauthenticatedHeader Sidebar={sidebar} />
          )
        }
      })()}


      <Dialog 
        open={open}
        onClose={close}
        fullWidth
        fullScreen={true}
        keepMounted={true}
        PaperComponent={params => (
          <div role='dialog' css={styles.paper}>
            {params.children}
          </div>
        )}
        TransitionComponent={SlideTransition}
      >
        <SearchPageMobile close={close} />
      </Dialog>
    </header>
  )
}



const mapStateToProps = state => {
  return { 
    isAuthenticated: state.user.isAuthenticated,
  }
}


export default connect(mapStateToProps, {index, saveNotificationLinks})(Header)



