import {useEffect, useRef, useState} from 'react'

import {useSelector, useDispatch} from 'react-redux'

import {isEmpty} from 'lodash'

import {setMarkers, addMarkers, setClickedMarkerData} from '../actions'

import {MarkerClusterer} from '@googlemaps/markerclusterer'



import {
  markerQueueSelector,
  venueMarkersSelector,
  userMarkerSelector,
  isUserLocatedSelector,
  userCoordinatesSelector,
  searchVenueLinksSelector,
  filterSearchTypeSelector,
  clickedMarkerDataSelector,
  performanceMarkersSelector,
  searchPerformanceLinksSelector
} from '../selectors'


import {
  icons, 
  createMapMarker,
  createScaledIcon, 
  createVenueInfoWindow,
  createPerformanceMapInfoWindow
} from '../helpers'





function createMarkers(map, items, clickCallback) {
  const markers = {}
  const mapBounds = map.getBounds()


  // For each item, create an info window and marker
  for (const item of items) {

    const type = item.type

    const coordinates = {
      lat: item.attributes.coordinates[1],
      lng: item.attributes.coordinates[0]
    }



    // Create new marker
    const marker = createMapMarker(coordinates, type)

    marker.data = item

    // Only add the marker if its within the bounds of the viewport
    if (mapBounds.contains(marker.getPosition())) {
      marker.setMap(map)
    }


    // Handle what happens when user clicks a marker
    marker.addListener('click', _ => {
      marker.setIcon({
        url: icons.selected,
        anchor: new window.google.maps.Point(20, 50),
        scaledSize: new window.google.maps.Size(40, 50)
      })

      marker.setZIndex(10)

      clickCallback(item)
    })


    // Add new markers and info windows to collection
    markers[item.id] = marker
  }



  // Add a marker clusterer to manage the markers.
  // new MarkerClusterer({markers: Object.values(markers), map})

  return markers
}





export function useCreateMapMarkers(map) {

  const [isLibraryLoaded, setIsLibraryLoaded] = useState(false)

  const dispatch = useDispatch()

  const markerQueue = useSelector(markerQueueSelector)

  const infoWindow = useRef()
  const selectedMarker = useRef()

  // User
  const userMarker = useSelector(userMarkerSelector)
  const isUserLocated = useSelector(isUserLocatedSelector)
  const userCoordinates = useSelector(userCoordinatesSelector)


  const clickedMarkerData = useSelector(clickedMarkerDataSelector)

  // Venues
  const venueLinks = useSelector(searchVenueLinksSelector)
  const venueMarkers = useSelector(venueMarkersSelector)


  // Performances
  const performanceLinks = useSelector(searchPerformanceLinksSelector)
  const performanceMarkers = useSelector(performanceMarkersSelector)


  const searchTypes = useSelector(filterSearchTypeSelector)



  // This fires when the map first loads, when a user pans, zooms, and 
  // when the viewport is chaning in general. In general, avoid adding markers
  // and performing actions when the bounds are changing. Instead, wait for the
  // map to become idel (next useEffect)
  // useEffect(() => {
  //   let boundsListener


  //   if (map) {
  //     boundsListener = map.addListener('bounds_changed', () => {
  //       setIsMapReady(true)
  //       setIsMapLoaded(true)
  //     })
  //   }


  //   return () => {
  //     if (boundsListener) {
  //       window.google.maps.event.removeListener(boundsListener)
  //     }
  //   }
  // }, [map])


  // Load the necessary libraries if they are not loaded already
  useEffect(() => {
    async function loadLibraries() {
      const {Map, InfoWindow} = await window.google.maps.importLibrary('maps')
      const {AdvancedMarkerElement} = await window.google.maps.importLibrary('marker')

      setIsLibraryLoaded(true)
    }

    loadLibraries()
  }, [])




  // This fires when the map goes from panning and zooming to becoming
  // idle. Once the map becomes idle, this effect checks to see if 
  // there are markers that should be added based on if the marker location
  // is inside the user's viewport.
  useEffect(() => {
    let idleListener


    if (map) {
      idleListener = map.addListener('idle', () => {
        const mapBounds = map.getBounds()


        if (searchTypes.includes('venues')) {
          Object.values(venueMarkers).forEach((marker, index) => {
            if (mapBounds.contains(marker.getPosition())) {
              window.setTimeout(() => {
                marker.setMap(map)
              }, Math.ceil(index /10) * 100)
            }
          })
        }



        if (searchTypes.includes('performances')) {
          Object.values(performanceMarkers).forEach((marker, index) => {
            if (mapBounds.contains(marker.getPosition())) {
              window.setTimeout(() => {
                marker.setMap(map)
              }, Math.ceil(index /10) * 100)
            }
          })
        }
      })
    }


    return () => {
      if (idleListener) {
        window.google.maps.event.removeListener(idleListener)
      }
    }
  }, [
    map, 
    searchTypes,
    venueMarkers, 
    performanceMarkers
  ])




  // Adds new markers
  useEffect(() => {
    if (map) {

      Object.keys(markerQueue).forEach(type => {

        const items = markerQueue[type]

        const singularType = type.slice(0, -1)

        // Current page of pagination links
        const linksPage = type === 'performances' ? performanceLinks.page : venueLinks.page


        if (items.length) {
          // The callback function that gets called when user clicks a marker
          const clickCallback = item => dispatch(setClickedMarkerData(item))

          // Add markers to the map
          const markers = createMarkers(map, items, clickCallback)


          // Clear the queue
          dispatch({type: 'CLEAR_' + singularType.toUpperCase() + '_MAP_MARKER_QUEUE'})


          // Save the markers to the store
          // -----------------------------
          // If this is a new search then remove the previous markers and set the new ones...
          if (linksPage === 1 && isEmpty(performanceMarkers)) {
            dispatch(setMarkers(markers, singularType.toUpperCase()))
          // Otherwise add the new markers to dictionary of existing markers
          } else {
            dispatch(addMarkers(markers, singularType.toUpperCase()))
          }
        }
      })
    }
  }, [
    map,
    dispatch,
    markerQueue,
    venueLinks.page,
    venueLinks.first_url,
    performanceLinks.page,
    performanceLinks.first_url,
    performanceMarkers
  ])





  // Add the user marker and center the map to the users location.
  // Once the user marker is added, then the map is ready to add 
  // other markers and is avaiable for use.
  useEffect(() => {
    if (map && isUserLocated && !userMarker) {
      try {
        const lat = userCoordinates.lat
        const lng = userCoordinates.lng

        // Check that coordinates are valid
        const latlng = new window.google.maps.LatLng(lat, lng)

        const marker = new window.google.maps.Marker({
          map,
          icon: {url: 'data:image/svg+xml;utf-8, ' + icons.user},
          position: latlng
        })

        // Save a reference to the user marker 
        dispatch({type: 'SET_USER_MARKER', payload: marker})

      } catch {
        console.log('TODO: Handle error in useCreateMapMarkers - 0')
      }
    }
  }, [
    map, 
    dispatch, 
    userMarker, 
    isUserLocated, 
    userCoordinates.lat, 
    userCoordinates.lng
  ])





  // The effect does two things:
  // 1. Creates a new info window for the marker that's clicked
  // 2. Changes the current clicked markers state back to the original state
  useEffect(() => {
    // If a marker's clicked data is empty, then return
    if (isEmpty(clickedMarkerData)) {
      return
    } 


    const {id, type} = clickedMarkerData


    // If the currently selected marker has the same id and type as the newly clicked marker
    // then the user is clicking the same marker and nothing should be done
    if (selectedMarker.current) {
      if (selectedMarker.current.data.id === id && selectedMarker.current.data.type === type) {
        return
      }
    }


    // Select the clicked marker
    let marker


    if (type === 'venue') {
      marker = venueMarkers[id]
    } else {
      marker = performanceMarkers[id]
    }


    marker.setIcon({
      url: icons.selected,
      anchor: new window.google.maps.Point(20, 50),
      scaledSize: new window.google.maps.Size(40, 50)
    })


    if (marker) {
      // Change the current clicked markers state back to the original state
      if (selectedMarker.current) {
        const selectedType = selectedMarker.current.data.type
        const icon = createScaledIcon(icons[selectedType])
        const zIndex = (selectedType === 'venue' ? 5 : 7)

        selectedMarker.current.setIcon(icon)
        selectedMarker.current.setZIndex(zIndex)
      }

      // If an info window is open, close it
      if (infoWindow.current) {
        infoWindow.current.close()
      }


      // Create a new info window
      if (type === 'venue') {
        infoWindow.current = createVenueInfoWindow(clickedMarkerData)
      } else {
        infoWindow.current = createPerformanceMapInfoWindow(clickedMarkerData)
      }


      // Handle what happens when user clicks x on the info window
      window.google.maps.event.addListener(infoWindow.current, 'closeclick', () => {
        const icon = createScaledIcon(icons[type])
        const zIndex = (type === 'venue' ? 5 : 7)

        marker.setIcon(icon)
        marker.setZIndex(zIndex)

        selectedMarker.current = null
      })


      // Open the new info window
      infoWindow.current.open({map, anchor: marker})

      selectedMarker.current = marker
    }

  }, [
    map,
    dispatch,
    venueMarkers,
    performanceMarkers,
    clickedMarkerData
  ])




  useEffect(() => {

  })

}




